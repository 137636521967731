
<!--
 * @Description: 分销设置
 * @Author: 琢磨先生
 * @Date: 2022-06-18 03:20:40
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-09-09 06:52:47
-->

<template>
  <el-card class="box">
    <div style="margin-bottom: 20px">分销设置</div>
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-form-item label="活动开关">
        <span v-if="!visible">
          <el-tag type="success" v-if="form.referral_enabled=='1'" size="small">已开启</el-tag>
          <el-tag type="danger" v-else size="small">关闭</el-tag>
          <span>开启后才能进行分销活动</span>
        </span>
        <el-switch
          v-if="visible"
          v-model="form.referral_enabled"
          active-value="1"
          inactive-value="0"
        ></el-switch>
        <div class="help-block" v-if="visible">开启后才能进行分销活动</div>
      </el-form-item>

      <el-form-item label="直接上级抽佣比例">
        <span v-if="!visible">
          <el-link type="primary" :underline="false">{{form.referral_percent}}%</el-link>，示例：A邀请了B，B后续只要有消费，A能获得的佣金比例。
        </span>
        <el-col :span="5" v-if="visible">
          <el-input v-model="form.referral_percent">
            <template #suffix>%</template>
          </el-input>
        </el-col>
        <div class="help-block" v-if="visible">A邀请了B，B后续只要有消费，A能获得的佣金比例。</div>
      </el-form-item>
      <el-form-item label="开启二级分销">
        <span v-if="!visible">
          <el-tag type="success" v-if="form.referral_second_level_enabled=='1'" size="small">已开启</el-tag>
          <el-tag type="danger" v-else size="small">关闭</el-tag>
        </span>
        <el-switch
          v-if="visible"
          v-model="form.referral_second_level_enabled"
          active-value="1"
          inactive-value="0"
        ></el-switch>
      </el-form-item>
      <el-form-item label="上二级抽佣比例">
        <span v-if="!visible">
          <el-link type="primary" :underline="false">{{form.referral_second_level_percent}}%</el-link>，示例：A邀请新会员B，B后续发展了新会员C，C消费时，A所能获得的佣金比例。
        </span>
        <el-col :span="5" v-if="visible">
          <el-input v-model="form.referral_second_level_percent" v-if="visible">
            <template #suffix>%</template>
          </el-input>
        </el-col>
        <div class="help-block" v-if="visible">A邀请新会员B，B后续发展了新会员C，C消费时，A所能获得的佣金比例。</div>
      </el-form-item>
      <el-form-item label="开启自购计算佣金">
        <span v-if="!visible">
          <el-tag type="success" v-if="form.referral_self_buy_enabled=='1'" size="small">已开启</el-tag>
          <el-tag type="danger" v-else size="small">关闭</el-tag>
          <span>若开启“分销员自购计算佣金”，当分销员自己购买时可获得一级抽佣，上二级分销无法获得佣金。</span>
        </span>
        <el-switch
          v-if="visible"
          v-model="form.referral_self_buy_enabled"
          active-value="1"
          inactive-value="0"
        ></el-switch>
        <div class="help-block" v-if="visible">若开启“分销员自购计算佣金”，当分销员自己购买时可获得一级抽佣，上二级分销无法获得佣金。</div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" link @click="visible = true" size="small" v-if="!visible">修改</el-button>
        <el-button type="primary" @click="onSubmit" v-if="visible" :loading="saving">确定</el-button>
        <el-button @click="visible = false" v-if="visible" :disabled="saving">取消</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
import common_api from "@/http/common_api";
export default {
  data() {
    return {
      visible: false,
      saving: false,
      form: {},
      settings: {},
      rules: {
        score_get_by_house: [
          { required: true, message: "请输入", trigger: "blur" },
          { pattern: /^[1-9]\d*$/, message: "请输入整数", trigger: "blur" },
        ],
        score_get_by_goods: [
          { required: true, message: "请输入", trigger: "blur" },
          { pattern: /^[1-9]\d*$/, message: "请输入整数", trigger: "blur" },
        ],
        score_equal_to_money: [
          { required: true, message: "请输入", trigger: "blur" },
          {
            // pattern: /(^0(.\d{1,3})?$)|(^1$)/,
            pattern: /^(0.\d{1,3}|0|1)$/,
            message: "请输入0-1之间的数字",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      common_api.get_settings().then((res) => {
        if (res.code == 0) {
          this.settings = res.data;
          this.form = Object.assign({}, this.settings);
        }
      });
    },
    /**
     * 提交保存
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.$http
            .post("/admin/v1/setting/save/referral", this.form)
            .then((res) => {
              this.saving = false;
              if (res.code == 0) {
                this.visible = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.loadData();
              }
            })
            .cath(() => {
              this.saving = false;
            });
        }
      });
    },
  },
};
</script>

<style scoped>
</style>